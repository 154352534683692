import { createModuleApi } from 'momai'

const phoneApi = <T>() => createModuleApi<T>('phone')

const sendChangeMobileCode = (mobile: string) => phoneApi<string>()({
  subUrl: `mobile-change/${mobile}`
})

const verifyChangePhoneCode = (params: { mobile: string; code: string }) => phoneApi<boolean>()({
  subUrl: 'verify-change-phone-code',
  params,
  method: 'post'
})

export {
  sendChangeMobileCode,
  verifyChangePhoneCode
}
