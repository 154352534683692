
import { sendChangeMobileCode, verifyChangePhoneCode } from '@/api/universal/phone'
import { Toast } from 'vant'
import { user } from '@/states/login'
import { goBack } from '@/states/layout'

export default {
  setup: () => {
    return {
      send: (mobile: string, startCount: () => void) => {
        sendChangeMobileCode(mobile).then(() => {
          Toast.success('验证码发送成功！')
          startCount()
        }).catch(({ code }) => {
          if (code === 5) {
            startCount()
          }
        })
      },
      verify: (p: any) => {
        verifyChangePhoneCode(p).then(() => {
          user.value.mobile = p.mobile
          Toast.success('手机号更改成功！')
          goBack()
        })
      }
    }
  }
}
